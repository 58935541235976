
import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';
import ScrollToTop from "./component/layout/scrolltop";
import AboutPage from "./pages/about";
import ContactUs from "./pages/contact";
import HomePage from './pages/home';
import LogIn from "./pages/login";
import SignUp from "./pages/signup";
import ErrorPage from "./pages/errorpage";
import ViewPick from "./pages/view-pick";
import ViewFreePick from "./pages/view-free-pick";
import ViewPackage from "./pages/view-package";
import ViewSubscription from "./pages/view-subscription";
import Dashboard from "./pages/dashboard";
import Packages from "./pages/packages";
import Subscriptions from "./pages/subscriptions";
import Picks from "./pages/picks";
import { AuthProvider } from "./context/AuthContext";
import FreePicks from "./pages/freepicks";
import TermsOfServices from "./pages/termsofservices";
import PrivacyPoliciesPage from './pages/privacypoliciespage';
import LeaderboardPage from "./pages/leaderboardpage";
import HandicapperPage from "./pages/view-handicapper";
import VerifyEmail from "./pages/verify";
import ForgotPassword from "./pages/forgotpassword";
import ResetPassword from "./pages/resetpassword";
import FAQ from "./pages/faq";
import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
import Refund from "./pages/refund";
import Offers from "./pages/offers";
import BlogPage from "./pages/blog";
import Blog1 from "./pages/blog1";
import Blog2 from "./pages/blog2";
import Blog3 from "./pages/blog3";
import Blog4 from "./pages/blog4";
import Blog5 from "./pages/blog5";
import Blog6 from "./pages/blog6";
import Blog7 from "./pages/blog7";
import Blog8 from "./pages/blog8";
import Blog9 from "./pages/blog9";
import Blog10 from "./pages/blog10";
import Blog11 from "./pages/blog11";
import Blog12 from "./pages/blog12";
import Blog13 from "./pages/blog13";
import Blog14 from "./pages/blog14";
import Blog15 from "./pages/blog15";


function App() {
	return (
		<AuthProvider>
			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="about" element={<AboutPage />} />
					<Route path="*" element={<ErrorPage />} />
					<Route path="blogs" element={<BlogPage />} />
					<Route path="contact" element={<ContactUs />} />
					<Route path="login" element={<LogIn />} />
					<Route path="signup" element={<SignUp />} />
					<Route path="pick/:id" element={<ViewPick />} />
					<Route path="package/:id" element={<ViewPackage />} />
					<Route path="subscription/:id" element={<ViewSubscription />} />
					<Route path="dashboard" element={<Dashboard />} />
					<Route path="refund" element={<Refund />} />
					<Route path="picks" element={<Picks />} />
					<Route path="packages" element={<Packages />} />
					<Route path="subscriptions" element={<Subscriptions />} />
					<Route path="leaderboard" element={<LeaderboardPage />} />
					<Route path="free-picks" element={<FreePicks />} />
					<Route path="free-pick/:id" element={<ViewFreePick />} />
					<Route path="terms-of-service" element={<TermsOfServices />} />
					<Route path="privacy-policy" element={<PrivacyPoliciesPage />} />
					<Route path="handicapper/:id" element={<HandicapperPage />} />
					<Route path="verify/:token" element={<VerifyEmail />} />
					<Route path="forgot-password" element={<ForgotPassword />} />
					<Route path="reset-password/:token" element={<ResetPassword />} />
					<Route path="faq" element={<FAQ />} />
					<Route path="success" element={<Success />} />
					<Route path="cancel" element={<Cancel />} />
					<Route path="offers" element={<Offers />} />
					<Route path="blogs/why-legalizing-sports-betting-in-california-matters" element={<Blog1 />} />
					<Route path="blogs/the-evolution-of-sports-betting-in-the-usa-a-focus-on-california" element={<Blog2 />} />
					<Route path="blogs/the-evolution-of-sports-betting-in-california-legal-milestones-and-ongoing-debates" element={<Blog3 />} />
					<Route path="blogs/high-profile-sports-betting-scandals-in-california-lessons-learned" element={<Blog4 />} />
					<Route path="blogs/nba-trade-moves-heading-toward-the-all-star-break-impact-on-title-contenders" element={<Blog5 />} />
					<Route path="blogs/sports-betting-californias-current-climate-why-hitthepickcom-is-the-most-reliable-choice" element={<Blog6 />} />
					<Route path="blogs/the-ultimate-guide-to-sports-betting-in-california-top-sites-tips-and-legal-insights-for-2025" element={<Blog7 />} />
					<Route path="blogs/the-battle-for-legal-sports-betting-in-california-why-its-time-for-change" element={<Blog8 />} />
					<Route path="blogs/californias-underground-betting-scene-how-illegal-bookies-thrive-in-the-shadows" element={<Blog9 />} />
					<Route path="blogs/arizona-and-nevada-how-californias-neighbors-cash-in-on-legal-sports-betting" element={<Blog10 />} />
					<Route path="blogs/why-california-needs-sports-betting-to-fix-its-infrastructure-crisis" element={<Blog11 />} />
					<Route path="blogs/when-will-california-legalize-sports-betting" element={<Blog12 />} />
					<Route path="blogs/how-many-illegal-bookies-are-there-in-california" element={<Blog13 />} />
					<Route path="blogs/how-popular-is-dfs-daily-fantasy-sports" element={<Blog14 />} />
					<Route path="blogs/how-much-is-bet-on-march-madness" element={<Blog15 />} />
				</Routes>
			</BrowserRouter>
		</AuthProvider>
	);
}

export default App;
