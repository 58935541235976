import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Handicapperpicks from './../component/section/handicapperpicks';

const ViewHandicapper = () => {
    const { id } = useParams(); // Extract id from the URL
    const [handicapperDetails, setHandicapperDetails] = useState({
        firstName: "",
        lastName: "",
        email: "",
        bio: "",
        profileImage: ""
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchHandicapperDetails = async () => {
            try {
                const response = await fetch(`${baseURL}/api/user/get-handicapper/${id}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch handicapper details");
                }
                const data = await response.json();
                setHandicapperDetails({
                    id: data._id || "N/A",
                    firstName: data.firstname || "N/A",
                    lastName: data.lastname || "N/A",
                    email: data.email || "N/A",
                    bio: data.bio || "No bio available.",
                    profileImage: data.profileImage || "/default-profile.jpg"
                });
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchHandicapperDetails();
    }, [baseURL, id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <head>
                <link rel="canonical" href={window.location.href} />
                <title>Top Betting Predictions & Tips for Today’s Matches | Hit the pick</title>
            </head>
            <Header />
            <PageHeader title={`${handicapperDetails.firstName} ${handicapperDetails.lastName}`} curPage={"Handicapper"} />
            <section className="fore-zero padding-top padding-bottom">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="zero-item">
                            <div className="zero-content text-center">
                                <img
                                    src={baseURL + "/uploads/handicappers/" + handicapperDetails.profileImage}
                                    alt={`${handicapperDetails.firstName} ${handicapperDetails.lastName}`}
                                    style={{
                                        width: "200px",
                                        borderRadius: "50%",
                                        height: "200px",
                                        objectFit: "cover",
                                    }}
                                />
                                <h2>{`${handicapperDetails.firstName} ${handicapperDetails.lastName}`}</h2>
                                {handicapperDetails.id === "67598da0622b0777aabad120" && (
                                    <Link
                                        to="https://x.com/WryCape?t=SaMnJrIIPAV54tLBO6Km6w&s=09"
                                        className="default-button mt-2 mb-2"
                                    >
                                        <span>X</span>
                                    </Link>
                                )}
                                {handicapperDetails.id === "67598dfd622b0777aabad12c" && (
                                    <>
                                        <Link
                                            to="https://x.com/Loudsportspicks"
                                            className="default-button mt-2 mb-2"
                                        >
                                            <span>X</span>
                                        </Link>
                                        <Link
                                            to="https://www.instagram.com/loudemond_betting/"
                                            className="default-button mt-2 mb-2"
                                        >
                                            <span>Instagram</span>
                                        </Link>
                                    </>
                                    
                                )}
                                <p>Email: {handicapperDetails.email}</p>
                                <p>Bio: {handicapperDetails.bio}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Handicapperpicks/>
            <Footer />
        </>
    );
};

export default ViewHandicapper;
