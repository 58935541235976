import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";

const HotPicksCarousel = () => {
    const [hotPicks, setHotPicks] = useState([]);

    useEffect(() => {
        const fetchHotPicks = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/hot-picks`);
            setHotPicks(response.data);
        };
        fetchHotPicks();
    }, []);

    return (
        <>
            <div className="hot-pick-carousel" style={{ backgroundColor: 'rgba(8, 18, 90, 0.3)', padding: '20px', position: 'relative', top: '201px' }}>
                <h4 style={{ textAlign: 'center', backgroundColor: '#ff0052', padding: '5px' }}>Today's Hot Picks</h4>
                <Carousel>
                    {hotPicks.map((hotPick) => (
                        <Carousel.Item key={hotPick._id}>
                            <div className="carousel-content">
                                <h3>{hotPick?.text}</h3>
                                <p>{hotPick?.pick?.title}</p>
                                {
                                    hotPick?.url !== '' ? (
                                        <Link
                                            to={hotPick?.url}
                                            className="default-button reverse-effect"
                                            target='_black'
                                        >
                                            <span>Click Here</span>
                                        </Link>
                                    ) : ''
                                }
                                {/* <Link
                                    to={`/handicapper/${hotPick.pick}`}
                                    className="default-button reverse-effect"
                                >
                                    <span>View</span>
                                </Link> */}
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </>
    );
};

export default HotPicksCarousel;