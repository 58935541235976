import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import TermsOfServicesContent from "../component/section/termsofservicescontent";

const TermsOfServices = () => {

    return (
        <>
            <head>
                <link rel="canonical" href={window.location.href} />
                <title>Terms Of Service | Hit the pick</title>
            </head>
            <Header />
            <PageHeader title={"Terms of service"} curPage={"Terms of service"} />
            <TermsOfServicesContent />
            <Footer />
        </>
    );
};

export default TermsOfServices;
