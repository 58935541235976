import React, { useState, useEffect } from "react";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const GameList = () => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null); // Tracks the selected item for payment
    const [showPaymentModal, setShowPaymentModal] = useState(false); // Modal visibility
    const [loadingItemId, setLoadingItemId] = useState(null); // Tracks which button is loading
    const [userCredits, setUserCredits] = useState(0.0); // Tracks user's available credits
    const [cardDetails, setCardDetails] = useState({
        cardNumber: '',
        expiryDate: '',
        cvv: ''
    });

    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchGameList = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/user/offers`);
                setItems(response.data.offers);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching game list:", error);
                setIsLoading(false);
            }
        };

        const fetchUserCredits = async () => {
            try {
                const userToken = localStorage.getItem("frontend_token");
                const response = await axios.get(`${baseURL}/api/user/credits`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });
                setUserCredits(parseFloat(response.data.credits) || 0);
            } catch (error) {
                console.error("Error fetching user credits:", error);
            }
        };

        fetchGameList();
        fetchUserCredits();
    }, [baseURL]);

    const handleApprove = async (orderID) => {
        try {
            const userToken = localStorage.getItem("frontend_token");
            await axios.post(
                `${baseURL}/api/user/capture-order`,
                {
                    orderID,
                    type: "pick",
                    itemId: selectedItem._id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            alert("Payment successful!");
            window.location.href = "/dashboard";
        } catch (error) {
            console.error("Error capturing PayPal order:", error);
            alert("Failed to purchase.");
        } finally {
            setShowPaymentModal(false);
            setLoadingItemId(null);
        }
    };

    const handlePurchase = (item) => {
        const userToken = localStorage.getItem("frontend_token");

        if (!userToken) {
            window.location.href = "/login";
            return;
        }

        setSelectedItem(item);
        setLoadingItemId(item._id);
        setShowPaymentModal(true);
    };

    const handleCreditsPayment = async () => {
        setIsLoading(true);
        try {
            const userToken = localStorage.getItem("frontend_token");
            const response = await axios.post(
                `${baseURL}/api/user/pay-with-credits`,
                {
                    itemId: selectedItem.id,
                    type: "offer",
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            if (response.data.success) {
                alert("Payment successful using credits!");
                window.location.href = "/dashboard";
            } else {
                alert("Failed to purchase using credits.");
            }
        } catch (error) {
            console.error("Error during credits payment:", error);
            alert("Failed to purchase using credits.");
        } finally {
            setShowPaymentModal(false);
            setLoadingItemId(null);
            setIsLoading(false);
        }
    };

    const handleCardPayment = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const userToken = localStorage.getItem("frontend_token");
            const response = await axios.post(
                `${baseURL}/api/user/pay-with-card`,
                {
                    itemId: selectedItem.id,
                    type: "offer",
                    amount: 25,
                    cardDetails
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            if (response.data.success) {
                alert("Payment successful using card!");
                window.location.href = "/dashboard";
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error("Error during card payment:", error);
            alert("Failed to purchase using card.");
        } finally {
            setShowPaymentModal(false);
            setLoadingItemId(null);
            setIsLoading(false);
        }
    };

    const handleModalClose = () => {
        setShowPaymentModal(false);
        setLoadingItemId(null);
    };

    const handleCardDetailsChange = (e) => {
        const { name, value } = e.target;
        setCardDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const handleExpiryDateChange = (e) => {
        let { value } = e.target;
        if (value.length === 2 && !value.includes('/')) {
            value = value + '/';
        }
        setCardDetails(prevDetails => ({
            ...prevDetails,
            expiryDate: value
        }));
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
            <section className="collection-section padding-top padding-bottom">
                <div className="container">
                    <div className="section-header">
                        <h2>Offers</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center collection-grid GameListStyle">
                            {items.map((item) => {
                                const { id, name, description, price } = item;
                                return (
                                    <div className="col-12" key={id}>
                                        <div className="match-item item-layer">
                                            <div className="match-inner">
                                                <div className="match-content bg-white">
                                                    <div className="row gy-4 align-items-center justify-content-center">
                                                        <div className="col-xl-2 col-md-2 order-md-2">
                                                            <div className="match-game-team">
                                                                <h4 style={{ color: "black" }}>${Number(price).toFixed(2)}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-md-6 order-md-6">
                                                            <div className="match-game-info">
                                                                <h4 style={{ color: "black" }}>{name}</h4>
                                                                <p className="d-flex flex-wrap justify-content-center justify-content-md-start">
                                                                    <span className="match-date">{description}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-2 col-md-2 order-md-2">
                                                            <div className="match-game-social">
                                                                <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center">
                                                                    <li>
                                                                        <button
                                                                            className="default-button reverse-effect"
                                                                            onClick={() => handlePurchase(item)}
                                                                            disabled={loadingItemId === id}
                                                                        >
                                                                            <span>
                                                                                {loadingItemId === id
                                                                                    ? "Loading..."
                                                                                    : "Purchase"}
                                                                            </span>
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {showPaymentModal && selectedItem && (
                    <div className="modal-overlay">
                        <div className="modal-content bg-white p-4" style={{ height: 'auto', borderRadius: '10px' }}>
                            <button className="close-button mt-1" style={{ color: 'black' }} onClick={handleModalClose}>
                                &times;
                            </button>
                            <div className="payment-options">
                                {/* Credits Payment */}
                                <div className="credits-payment">
                                    <h4 style={{ color: 'black' }}>Pay with Credits</h4>
                                    <button
                                        onClick={handleCreditsPayment}
                                        className="default-button reverse-effect mb-4"
                                        disabled={userCredits < 30 || isLoading}
                                    >
                                        {isLoading ? (
                                            <>
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                Processing...
                                            </>
                                        ) : (
                                            <>
                                                <span>Use Credits (${userCredits.toFixed(2)} available)</span>
                                            </>
                                        )}
                                    </button>
                                </div>

                                {/* PayPal Payment */}
                                <div className="paypal-payment">
                                    <h4 style={{ color: 'black' }}>Pay with PayPal</h4>
                                    <PayPalButtons
                                        style={{
                                            layout: "vertical",
                                            color: "gold",
                                            shape: "rect",
                                            label: "paypal",
                                        }}
                                        fundingSource="paypal"
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                purchase_units: [
                                                    {
                                                        amount: {
                                                            value: (25).toFixed(2),
                                                        },
                                                    },
                                                ],
                                            });
                                        }}
                                        onApprove={(data) => handleApprove(data.orderID)}
                                        onCancel={handleModalClose}
                                        onError={(err) => {
                                            console.error("PayPal error:", err);
                                            alert("Failed to purchase.");
                                            handleModalClose();
                                        }}
                                    />
                                </div>

                                {/* Card Payment */}
                                <div className="card-payment">
                                    <h4 style={{ color: 'black' }}>Pay with Card</h4>
                                    <form onSubmit={handleCardPayment}>
                                        <div className="mb-3">
                                            <label htmlFor="cardNumber" className="form-label" style={{ color: 'black' }}>Card Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="cardNumber"
                                                name="cardNumber"
                                                value={cardDetails.cardNumber}
                                                onChange={handleCardDetailsChange}
                                                maxLength="16"
                                                pattern="\d{16}"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="expiryDate" className="form-label" style={{ color: 'black' }}>Expiry Date</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="expiryDate"
                                                name="expiryDate"
                                                value={cardDetails.expiryDate}
                                                onChange={handleExpiryDateChange}
                                                placeholder="MM/YY"
                                                maxLength="5"
                                                pattern="\d{2}/\d{2}"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="cvv" className="form-label" style={{ color: 'black' }}>CVV</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="cvv"
                                                name="cvv"
                                                value={cardDetails.cvv}
                                                onChange={handleCardDetailsChange}
                                                maxLength="4"
                                                pattern="\d{3,4}"
                                                required
                                            />
                                        </div>
                                        <button type="submit" className="default-button reverse-effect mb-4" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                    Processing...
                                                </>
                                            ) : (
                                                <>
                                                    <span>Pay with Card</span>
                                                </>
                                            )}
                                        </button>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                )}
            </section>
        </PayPalScriptProvider>
    );
};

export default GameList;