import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import PackagesList from "../component/section/packageslist";

const Packages = () => {

    return (
        <>
            <head>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content="Betting Predictions" />
                <meta name="description" content="Boost your betting success with our expert predictions and tips for today’s matches. We analyze stats, trends, and odds to bring you the best bets of the day!" />
                <title>Top Betting Predictions & Tips for Today’s Matches | Hit the pick</title>
            </head>
            <Header />
            <PageHeader title={"Packages"} curPage={"Packages"} />
            <PackagesList />
            <Footer />
        </>
    );
};

export default Packages;
