import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

class Blog5 extends Component {
    render() { 
        return (
            <Fragment>
                <head>
                    <link rel="canonical" href={window.location.href} />
                    <title>NBA Trade Moves Heading Toward the All-Star Break: Impact on Title Contenders | Hit the pick</title>
                </head>
                <Header />
                <PageHeader title={'NBA Trade Moves Heading Toward the All-Star Break: Impact on Title Contenders'} curPage={'Blog Details'} />
                <div className="blog-section blog-single padding-top padding-bottom">
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="row justify-content-center pb-15">
                                <div className="col-lg-12 col-12">
                                    <article>
                                        <div className="post-item-2">
                                            <div className="post-inner">
                                                <div className="post-content">
                                                    <div className="post-thumb mb-30"><img src="../assets/images/blog/blog-5-img-1.webp" alt="blog-img" /></div>
                                                    <h2>Golden State Warriors</h2>
                                                    <h4>Opening odds to win title – +3500</h4>
                                                    <h4>Current odds -- +5000</h4>
                                                    <p>The money is no object, and the Warriors just were not willing to give up on this season. They took an expensive flyer on the league’s biggest pouter, Jimmy Butler. The word on much-traveled Butler is that he’s good to go until he’s looking for money, and the Warriors took care of that with a two-year contract extension.</p>
                                                    <p>The future is the future, but for now, Golden State will try to make another playoff run or two built around the aged trio of Butler, Steph Curry, and Draymond Green, who will be a combined 107 years old when the post-season gets underway. The initial results are promising. In their first game together, the Old Three Warriors laid waste to the carcass of the Chicago Bulls, with Butler and Curry combining for 69 points, and Green doing Green things. The Warriors have more than a puncher’s chance of vaulting into the playoff scrum in the West, assuming Butler remains appeased.</p>
                                                    
                                                    <h2>Los Angeles Lakers</h2>
                                                    <h4>Opening odds to win title -- +4000</h4>
                                                    <h4>Current odds -- +1200</h4>
                                                    <p>Having Luka Doncic drop into your laps probably saved a lot of front office jobs. The franchise now has its cornerstone for the extended future, even if J.J. Redick now has to figure out how two ball-dominant players can work together in the same lineup. In Laker Land, there has to be a star, and with LeBron James on the bad side of 40, bringing on Doncic in a deal that LA didn’t even pursue is like finding money.</p>
                                                    <p>If nothing else, it keeps James interested for another year or two, similar to the vibe with Curry in Golden State. The issue now is convincing Doncic that it’s in his best interest to actually get in NBA shape. In the Finals last year, the Celtics pressured Doncic relentlessly when he had the ball and hunted him down when he didn’t. Things like that won’t happen with James on the court, and Laker opponents will now have to deal with Doncic when James rests. And that messy, now-canceled trade of Dalton Knecht to Charlotte will no doubt work itself out.</p>
                                                    
                                                    <h2>Dallas Mavericks</h2>
                                                    <h4>Opening odds to win title -- +900</h4>
                                                    <h4>Current odds -- +3300</h4>
                                                    <p>Here is all you need to know about how Mavericks fans feel about the Doncic trade: The team has had to beef up security around Dallas GM Nico Harrison. Amid all the screaming by the Mavs fan base, Harrison never quite got to explain WHY he dealt away a first-team NBA superstar for someone (Anthony Davis) seven years older.</p>
                                                    <p>The Mavs apparently didn’t like what they saw from Doncic in the Finals last spring and weren’t too happy about how Doncic was lackadaisical about spending time in the weight room. Combine that with wild speculation that Las Vegas-based owner Miriam Adelson actually WANTS the team to tank, which would grease a move to Nevada.</p>
                                                    <p>Right now, the official word is that the team has changed its persona to all defense all the time, and that Davis will – for a few years anyway – anchor a front line, while Kyrie Irving and Klay Thompson will deal with the offense.</p>
                                                    
                                                    <h2>Cleveland Cavaliers</h2>
                                                    <h4>Opening odds to win title -- +3500</h4>
                                                    <h4>Current odds -- +800</h4>
                                                    <p>Sixty minutes before the trade deadline, the Cavs made a move that could have an impact in a projected Eastern Conference finals matchup against Boston, acquiring DeAndre Hunter from the Hawks. Hunter is 6-foot-8 and has earned a good living in the league through his ability to defend forwards. Whether he can do it in a 7-game series against Jayson Tatum and/or Jaylen Brown is anyone’s guess, but the Cavs – who already boast a potent front line – now appear better equipped to handle Boston’s nightly 3-point barrage.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}
 
export default Blog5;