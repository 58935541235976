import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Leaderboard = () => {
    const [handicappersProfiles, setHandicappersProfiles] = useState([]);
    const [handicappers, setHandicappers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [duration, setDuration] = useState('7days'); // Default duration
    const baseURL = process.env.REACT_APP_BASE_URL;

    const fetchHandicappers = async (duration) => {
        try {
            const response = await axios.get(`${baseURL}/api/user/leaderboard?duration=${duration}`);
            setHandicappers(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching handicappers:", error);
            setIsLoading(false);
        }
    };

    const fetchHandicappersProfiles = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/user/get-handicappers`);
            setHandicappersProfiles(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching handicappers:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchHandicappers(duration);
        fetchHandicappersProfiles();
    }, [duration]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <section
            className="match-section padding-top padding-bottom"
            style={{ backgroundImage: "url(/assets/images/match/bg-2.jpg)" }}
        >
            <div className="container">
                <div className="section-header">
                    <h2>Leaderboard</h2>
                    <div className="duration-filter">
                        <label htmlFor="duration">Filter by duration: </label>
                        <select
                            id="duration"
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                        >
                            <option value="7days">Last 7 Days</option>
                            <option value="1month">Last 1 Month</option>
                            <option value="1year">Last 1 Year</option>
                        </select>
                    </div>
                </div>
                <div className="section-wrapper">
                    {/* Leaderboard Table */}
                    <div className="table-responsive mb-4">
                        <table className="table table-striped text-center">
                            <thead className="table-dark">
                                <tr>
                                    <th>#</th>
                                    <th>Handicapper</th>
                                    <th>Win/Loss</th>
                                </tr>
                            </thead>
                            <tbody>
                                {handicappers.map((handicapper, index) => {
                                    const wonPicks = handicapper.wonPicks || 0;
                                    const lostPicks = handicapper.lostPicks || 0;

                                    return (
                                        <tr key={handicapper.handicapperId}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link to={`/handicapper/${handicapper.handicapperId}`} style={{ color: "black" }}>
                                                    {handicapper.name}
                                                </Link>
                                            </td>
                                            <td>
                                                {wonPicks} / {lostPicks} 
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    {/* Handicappers Cards */}
                    <div className="row g-4 justify-content-center collection-grid">
                        {handicappersProfiles.map((handicapper) => (
                            <div className="col-6" key={handicapper._id}>
                                <div className="match-item item-layer">
                                    <div className="match-inner">
                                        <div className="match-content bg-white">
                                            <div className="row gy-4 align-items-center justify-content-center">
                                                <div className="col-xl-6 col-md-6 order-md-2">
                                                    <div className="col-12 text-center">
                                                        <Link
                                                            to={`/handicapper/${handicapper._id}`}
                                                            className="default-button reverse-effect"
                                                        >
                                                            <span>View</span>
                                                        </Link> &nbsp;
                                                        {handicapper._id === "674862ba3c13d47d1d5d586c" && (
                                                            <Link
                                                                to="https://x.com/WryCape?t=SaMnJrIIPAV54tLBO6Km6w&s=09"
                                                                className="default-button reverse-effect"
                                                                target="_blank"
                                                            >
                                                                <span>X</span>
                                                            </Link>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 order-md-1">
                                                    <div
                                                        className="match-game-info d-flex flex-wrap align-items-center justify-content-start"
                                                        style={{ gap: "30px" }}
                                                    >
                                                        <img
                                                            src={baseURL + "/uploads/handicappers/" + handicapper.profileImage}
                                                            alt={handicapper.firstname}
                                                            style={{
                                                                width: "100px",
                                                                borderRadius: "50%",
                                                                height: "100px",
                                                                objectFit: "cover",
                                                            }}
                                                        />
                                                        <h4 style={{ color: "black" }}>
                                                            {handicapper.firstname} {handicapper.lastname}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Leaderboard;