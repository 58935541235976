import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

let BlogContentListOne = [
    {
        imgUrl: 'assets/images/blog/blog-15-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: "How Much Is Bet on March Madness?",
        desc: "March Madness is one of the biggest sports betting events in the U.S., with billions wagered annually. In 2024 alone, over $15 billion was bet on the tournament, making it a prime event for sportsbooks, office pools, and casual bettors alike.",
        btnText: 'read more',
        pubDate: '30 March 2025',
        blogUrl: '/blogs/how-much-is-bet-on-march-madness'
    },
    {
        imgUrl: 'assets/images/blog/blog-14-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: "How Popular Is DFS (Daily Fantasy Sports)?",
        desc: "Daily Fantasy Sports (DFS) has exploded in popularity across the U.S., including California. Platforms like DraftKings and FanDuel have transformed the way sports fans engage with games, offering skill-based competitions instead of pure gambling.",
        btnText: 'read more',
        pubDate: '30 March 2025',
        blogUrl: '/blogs/how-popular-is-dfs-daily-fantasy-sports'
    },
    {
        imgUrl: 'assets/images/blog/blog-13-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: "How Many Illegal Bookies Are There in California?",
        desc: "With California's vast sports culture and a lack of legal betting options, illegal bookmaking is thriving. Estimates suggest that the underground sports betting market generates billions annually.",
        btnText: 'read more',
        pubDate: '30 March 2025',
        blogUrl: '/blogs/how-many-illegal-bookies-are-there-in-california'
    },
    {
        imgUrl: 'assets/images/blog/blog-12-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: "When Will California Legalize Sports Betting?",
        desc: "California sports betting remains a hot topic, with voters rejecting legalization efforts in 2022. However, industry experts predict another push for legalization in the upcoming 2026 ballot.",
        btnText: 'read more',
        pubDate: '30 March 2025',
        blogUrl: '/blogs/when-will-california-legalize-sports-betting'
    },
    {
        imgUrl: 'assets/images/blog/blog-11-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: "Why California Needs Sports Betting to Fix Its Infrastructure Crisis",
        desc: "California’s roads, bridges, and public transit systems are falling apart. The state faces a $150 billion infrastructure deficit, and traditional sources of funding — like gas taxes and federal aid — aren’t enough.",
        btnText: 'read more',
        pubDate: '03 March 2025',
        blogUrl: '/blogs/why-california-needs-sports-betting-to-fix-its-infrastructure-crisis'
    },
    {
        imgUrl: 'assets/images/blog/blog-10-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: "Arizona and Nevada – How California’s Neighbors Cash In on Legal Sports Betting",
        desc: "While California debates sports betting legalization, Arizona and Nevada are stacking billions in revenue.",
        btnText: 'read more',
        pubDate: '03 March 2025',
        blogUrl: '/blogs/arizona-and-nevada-how-californias-neighbors-cash-in-on-legal-sports-betting'
    },
    {
        imgUrl: 'assets/images/blog/blog-9-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: "California’s Underground Betting Scene – How Illegal Bookies Thrive in the Shadows",
        desc: "While Nevada and Arizona flourish with legal sports betting, California’s refusal to legalize has created a massive underground betting market.",
        btnText: 'read more',
        pubDate: '03 March 2025',
        blogUrl: '/blogs/californias-underground-betting-scene-how-illegal-bookies-thrive-in-the-shadows'
    },
    {
        imgUrl: 'assets/images/blog/blog-8-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: "The Battle for Legal Sports Betting in California – Why It’s Time for Change",
        desc: "California is one of the most progressive states in the U.S., but when it comes to sports betting, it's stuck in the past.",
        btnText: 'read more',
        pubDate: '03 March 2025',
        blogUrl: '/blogs/the-battle-for-legal-sports-betting-in-california-why-its-time-for-change'
    },
    {
        imgUrl: 'assets/images/blog/blog-7-img-1.jpg',
        imgAlt: 'HIT THE PICK',
        title: "The Ultimate Guide to Sports Betting in California: Top Sites, Tips, and Legal Insights for 2025",
        desc: 'Sports betting is one of the fastest-growing industries in the United States, and California is at the forefront of this exciting trend.',
        btnText: 'read more',
        pubDate: '24 Feb 2025',
        blogUrl: '/blogs/the-ultimate-guide-to-sports-betting-in-california-top-sites-tips-and-legal-insights-for-2025'
    },
    {
        imgUrl: 'assets/images/blog/blog-6-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: "Sports Betting & California's Current Climate: Why HITTHEPICK.COM Is the Most Reliable Choice",
        desc: 'California is making headlines due to major developments in politics, climate challenges, and even the sports world.',
        btnText: 'read more',
        pubDate: '24 Feb 2025',
        blogUrl: '/blogs/sports-betting-californias-current-climate-why-hitthepickcom-is-the-most-reliable-choice'
    },
    {
        imgUrl: 'assets/images/blog/blog-5-img-1.webp',
        imgAlt: 'HIT THE PICK',
        title: 'NBA Trade Moves Heading Toward the All-Star Break: Impact on Title Contenders',
        desc: 'The money is no object, and the Warriors just were not willing to give up on this season. They took an expensive flyer on the league’s biggest pouter, Jimmy Butler.',
        btnText: 'read more',
        pubDate: '10 Feb 2025',
        blogUrl: '/blogs/nba-trade-moves-heading-toward-the-all-star-break-impact-on-title-contenders'
    },
    {
        imgUrl: 'assets/images/blog/blog-4-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: 'High-Profile Sports Betting Scandals in California: Lessons Learned',
        desc: 'While sports betting offers entertainment and economic opportunities, it also carries the risk of scandals that can tarnish the integrity of sports and betting institutions.',
        btnText: 'read more',
        pubDate: '05 Feb 2025',
        blogUrl: '/blogs/high-profile-sports-betting-scandals-in-california-lessons-learned'
    },
    {
        imgUrl: 'assets/images/blog/blog-3-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: 'The Evolution of Sports Betting in California: Legal Milestones and Ongoing Debates',
        desc: "Sports betting in California has experienced a dynamic journey, marked by significant legal developments and ongoing debates. As the nation's most populous state, California's stance on sports wagering holds considerable influence.",
        btnText: 'read more',
        pubDate: '03 Feb 2025',
        blogUrl: '/blogs/the-evolution-of-sports-betting-in-california-legal-milestones-and-ongoing-debates'
    },
    {
        imgUrl: 'assets/images/blog/blog-2-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: 'The Evolution of Sports Betting in the USA: A Focus on California',
        desc: 'Sports betting has experienced an unprecedented rise in popularity across the United States in recent years, driven by legislative changes and evolving public attitudes.',
        btnText: 'read more',
        pubDate: '28 Jan 2025',
        blogUrl: '/blogs/the-evolution-of-sports-betting-in-the-usa-a-focus-on-california'
    },
    {
        imgUrl: 'assets/images/blog/blog-1-img-1.png',
        imgAlt: 'HIT THE PICK',
        title: 'Why Legalizing Sports Betting in California Matters',
        desc: 'California’s stance on sports betting has become a hot topic in recent years. Despite its enormous population and sports-loving culture, the state has yet to join the growing list of states with legal sports betting.',
        btnText: 'read more',
        pubDate: '25 Jan 2025',
        blogUrl: '/blogs/why-legalizing-sports-betting-in-california-matters'
    },
]

class BlogPage extends Component {
    render() { 
        return (
            <Fragment>
                <head>
                    <link rel="canonical" href={window.location.href} />
                    <title>Blogs | Hit the pick</title>
                </head>
                <Header />
                <PageHeader title={'OUR BLOG POSTS'} curPage={'Blog'} />
                <div className="blog-section padding-top padding-bottom">
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="row g-4 justify-content-center">
                                {BlogContentListOne.map((val, i) => (
                                    <div className="col-12" key={i}>
                                        <div className="blog-item">
                                            <div className="blog-inner d-flex flex-wrap align-items-center">
                                                <div className="blog-thumb w-xl-50 w-100">
                                                    <Link to={`${val.blogUrl}`}>
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} className="w-100" />
                                                    </Link>
                                                </div>
                                                <div className="blog-content p-4 w-xl-50 w-100">
                                                    <Link to={`${val.blogUrl}`}><h3>{val.title}</h3></Link>
                                                    <div className="meta-post">
                                                        <p>{val.pubDate}</p>
                                                    </div>
                                                    <p>{val.desc}</p>
                                                    <Link to={`${val.blogUrl}`} className="default-button"><span>{val.btnText} <i className="icofont-circled-right"></i></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}
 
export default BlogPage;