import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const title = "Register Now";

const SignUp = () => {
    const baseURL = process.env.REACT_APP_BASE_URL;

    const [formData, setFormData] = useState({
        regFName: "",
        regLName: "",
        regEmail: "",
        regPassword: "",
        regConPassword: "",
    });

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess("");

        const { regFName, regLName, regEmail, regPassword, regConPassword } = formData;

        // Validate input
        if (!regFName || !regLName || !regEmail || !regPassword || !regConPassword) {
            setError("All fields are required.");
            return;
        }

        if (regPassword !== regConPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const response = await fetch(`${baseURL}/api/auth/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    firstname: regFName,
                    lastname: regLName,
                    email: regEmail,
                    password: regPassword,
                    role: "user",
                }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.message || "Registration failed. Please try again.");
            }

            setSuccess(
                "Registration successful. Please check your email for the verification link."
            );
            setFormData({
                regFName: "",
                regLName: "",
                regEmail: "",
                regPassword: "",
                regConPassword: "",
            });
        } catch (error) {
            setError(error.message || "Something went wrong. Please try again.");
        }
    };

    return (
        <Fragment>
            <head>
                <link rel="canonical" href={window.location.href} />
                <title>Signup | Hit the pick</title>
            </head>
            <Header />
            <PageHeader title={"REGISTER"} curPage={"Sign Up"} />
            <div className="login-section padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        {error && <div className="error-message">{error}</div>}
                        {success && <div className="success-message">{success}</div>}
                        <form className="account-form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="regFName"
                                    value={formData.regFName}
                                    onChange={handleChange}
                                    placeholder="First Name *"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="regLName"
                                    value={formData.regLName}
                                    onChange={handleChange}
                                    placeholder="Last Name *"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    name="regEmail"
                                    value={formData.regEmail}
                                    onChange={handleChange}
                                    placeholder="Your Email *"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    name="regPassword"
                                    value={formData.regPassword}
                                    onChange={handleChange}
                                    placeholder="Password *"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    name="regConPassword"
                                    value={formData.regConPassword}
                                    onChange={handleChange}
                                    placeholder="Confirm Password *"
                                />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="d-block default-button">
                                    <span>Register Now</span>
                                </button>
                            </div>
                        </form>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">
                                Already have an account? <Link to="/login">Login</Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default SignUp;
