import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";

import BannerSection from "../component/section/banner";
import Leaderboard from "../component/section/leaderboard";
import Popup from "./popup";
import HotPicksCarousel from "../component/section/HotPickCarousal";
import BannerCarousal from "../component/section/BannerCarousal";
import VideoSection from './../component/section/video';


class HomePage extends Component {
    render() { 
        return (
            <Fragment>
                <head>
                    <link rel="canonical" href={window.location.href} />
                    <meta name="keywords" content="online betting tips" />
                    <meta name="description" content="Looking to boost your betting success? Discover expert online betting tips that can help you maximize your winnings, minimize risks, and make smarter wagers. Whether you're into sports betting, casino games, or live betting, these strategies will give you the edge you need!" />
                    <title>Top Online Betting Tips to Maximize Your Winnings | Hit the pick</title>
                </head>
                <Popup />
                <Header />
                <HotPicksCarousel />
                <BannerSection />
                <BannerCarousal />
                <VideoSection />
                <Leaderboard />
                <Footer />
            </Fragment>
        );
    }
}
 
export default HomePage;