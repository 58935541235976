import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import FreePicksList from "../component/section/freepickslist";

const FreePicks = () => {

    return (
        <>
            <head>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content="free betting tips​" />
                <meta name="description" content="Stop guessing and start winning! Discover free betting tips that actually work, tested by experts to help you make smarter wagers. Whether you're into sports betting or casino games, these strategies can give you the edge. Try them today!" />
                <title>Free Betting Tips That Actually Work Try Them Today | Hit the pick</title>
            </head>
            <Header />
            <PageHeader title={"Free Picks"} curPage={"Free Picks"} />
            <FreePicksList />
            <Footer />
        </>
    );
};

export default FreePicks;
