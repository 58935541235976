import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { useAuth } from "../context/AuthContext";

const title = "Login";

const LogIn = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASE_URL;

    const [credentials, setCredentials] = useState({
        email: "",
        userPass: "",
    });

    const [error, setError] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        try {
            const response = await fetch(`${baseURL}/api/auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: credentials.email,
                    password: credentials.userPass,
                    role: 'user',
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                // Handle specific error messages
                if (data.message) throw new Error(data.message);
                throw new Error("Invalid credentials. Please try again.");
            }

            const token = data.token;

            if (token) {
                login(token);
                navigate("/dashboard");
            }
        } catch (error) {
            setError(error.message || "Something went wrong. Please try again.");
        }
    };

    return (
        <Fragment>
            <head>
                <link rel="canonical" href={window.location.href} />
                <title>Login | Hit the pick</title>
            </head>
            <Header />
            <PageHeader title={"LOGIN"} curPage={"Login"} />
            <div className="login-section padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        {error && <div className="error-message">{error}</div>}
                        <form className="account-form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    value={credentials.email}
                                    onChange={handleChange}
                                    placeholder="Email *"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    name="userPass"
                                    value={credentials.userPass}
                                    onChange={handleChange}
                                    placeholder="Password *"
                                />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="d-block default-button">
                                    <span>Login</span>
                                </button>
                            </div>
                        </form>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">
                                Don’t Have an Account? <Link to="/signup">Sign Up</Link>
                            </span>
                            <span className="d-block cate pt-10">
                                Forgot Password? <Link to="/forgot-password">Reset</Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default LogIn;
