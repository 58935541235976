import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import PrivacyPolicies from "../component/section/privacypolicies";

const PrivacyPoliciesPage = () => {

    return (
        <>
            <head>
                <link rel="canonical" href={window.location.href} />
                <title>Privacy Policy | Hit the pick</title>
            </head>
            <Header />
            <PageHeader title={"Privacy Policy"} curPage={"Privacy Policy"} />
            <PrivacyPolicies />
            <Footer />
        </>
    );
};

export default PrivacyPoliciesPage;
