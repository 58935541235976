import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const ResetPassword = () => {
    const { token } = useParams(); // Extract token from URL
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASE_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess("");

        if (!password || !confirmPassword) {
            setError("Both password fields are required.");
            return;
        }

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const response = await fetch(`${baseURL}/api/auth/reset-password/${token}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ password }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || "Failed to reset password.");
            }

            setSuccess("Password reset successfully. Redirecting to login...");
            setTimeout(() => navigate("/login"), 3000);
        } catch (error) {
            setError(error.message || "Something went wrong. Please try again.");
        }
    };

    return (
        <>
            <head>
                <link rel="canonical" href={window.location.href} />
                <title>Reset Password | Hit the pick</title>
            </head>
            <Header />
            <PageHeader title={"Reset Password"} curPage={"Reset Password"} />
            <div className="login-section padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">Reset Password</h3>
                        {error && <div className="error-message">{error}</div>}
                        {success && <div className="success-message">{success}</div>}
                        <form className="account-form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter new password"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    placeholder="Confirm new password"
                                />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="d-block default-button">
                                    <span>Reset Password</span>
                                </button>
                            </div>
                        </form>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">
                                Back to <span onClick={() => navigate("/login")} style={{ cursor: "pointer", color: "#007bff" }}>Login</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ResetPassword;
