import React, { useState, useEffect } from 'react';
import axios from 'axios';


const VideoSection = () => {
    const subtitle = "our LATEST VIDEOS";
    const title = "check our latest videos";
    const [videos, setVideos] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get-youtube-videos`);
                setVideos(response.data.videos[0]);
            } catch (error) {
                setError('Error fetching videos');
            } finally {
                setLoading(false);
            }
        };

        fetchVideos();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const convertToEmbedLink = (link) => {
        if (!link) {
            return '';
        }
        const videoId = link.split('v=')[1];
        return `https://www.youtube.com/embed/${videoId}`;
    };

    return (
        <div className="video-section padding-top padding-bottom bg-attachment" style={{ backgroundImage: "url(/assets/images/video/bg.jpg)" }}>
            <div className="container">
                <div className="section-header">
                    <p>{subtitle}</p>
                    <h2>{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div key={1} className="video-item mb-3" style={{ display: 'flex', justifyContent: 'space-between', gap: '5px', flexWrap: 'wrap' }}>
                        <iframe
                            width="560"
                            height="315"
                            title="Hit The Pick"
                            src={convertToEmbedLink(videos?.link1)}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                        <iframe
                            width="560"
                            height="315"
                            title="Hit The Pick"
                            src={convertToEmbedLink(videos?.link2)}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                    </div>
                </div>
                <div className="section-wrapper">
                    <div key={2} className="video-item" style={{ display: 'flex', justifyContent: 'space-between', gap: '5px', flexWrap: 'wrap' }}>
                        <iframe
                            width="560"
                            height="315"
                            title="Hit The Pick"
                            src={convertToEmbedLink(videos?.link3)}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                        <iframe
                            width="560"
                            height="315"
                            title="Hit The Pick"
                            src={convertToEmbedLink(videos?.link4)}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoSection;