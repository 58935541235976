import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const VerifyEmail = () => {
    const { token } = useParams(); // Token extracted from the URL
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_BASE_URL;

    const [status, setStatus] = useState({
        loading: true,
        success: false,
        message: "",
    });

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await fetch(`${baseURL}/api/auth/verify/${token}`, {
                    method: "GET",
                });

                const data = await response.json();

                if (!response.ok) {
                    throw new Error(data.message || "Verification failed. Please try again.");
                }

                setStatus({
                    loading: false,
                    success: true,
                    message: "Your email has been successfully verified! You can now log in.",
                });
            } catch (error) {
                setStatus({
                    loading: false,
                    success: false,
                    message: error.message || "Verification failed. Please try again later.",
                });
            }
        };

        verifyEmail();
    }, [token, baseURL]);

    const handleLoginRedirect = () => {
        navigate("/login");
    };

    return (
        <>
            <head>
                <link rel="canonical" href={window.location.href} />
                <title>Verify | Hit the pick</title>
            </head>
            <Header />
            <PageHeader title={"Verify Email"} curPage={"Verify Email"} />
            <div className="login-section padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">Email Verification</h3>
                        {status.loading ? (
                            <p>Loading...</p>
                        ) : (
                            <div>
                                {status.success ? (
                                    <div className="success-message">
                                        <p>{status.message}</p>
                                        <button
                                            onClick={handleLoginRedirect}
                                            className="d-block default-button mt-3"
                                        >
                                            <span>Go to Login</span>
                                        </button>
                                    </div>
                                ) : (
                                    <div className="error-message">
                                        <p>{status.message}</p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default VerifyEmail;
