import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const Refund = () => {
    const [transactions, setTransactions] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [refundMethod, setRefundMethod] = useState('');
    const [refundDetails, setRefundDetails] = useState({});
    const [showRefundForm, setShowRefundForm] = useState(false);
    const userToken = localStorage.getItem("frontend_token");

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/transactions`, {
                    headers: { Authorization: `Bearer ${userToken}` },
                });
                setTransactions(response.data.data.filter(transaction => !transaction?.refundRequested));
            } catch (error) {
                // window.location.href = '/login';
            }
        };

        fetchTransactions();
    }, [userToken]);

    const handleRefundRequest = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/request-refund`, {
                transactionId: selectedTransaction._id,
                refundMethod,
                refundDetails
            },
            {
                headers: {
                    Authorization: `Bearer ${userToken}`
                },
            });
            alert('Refund requested successfully');
            setShowRefundForm(false);
        } catch (error) {
            // window.location.href = '/login';
            alert('Error requesting refund');
        }
    };

    return (
        <>
            <head>
                <link rel="canonical" href={window.location.href} />
                <title>Refund | Hit the pick</title>
            </head>
            <Header />
            <PageHeader title={"Refund"} curPage={"Refund"} />
            <section className="fore-zero padding-top padding-bottom">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="zero-item">
                            <div className="zero-content">
                                <button className="d-block default-button" style={{ margin: '0 auto' }} onClick={() => setShowRefundForm(true)}><span>Request Refund</span></button>
                                {showRefundForm && (
                                    <div className='account-wrapper'>
                                        <h2>Request Refund</h2>
                                        <div className="form-group mt-3">
                                            <label>Select Purchase:</label>
                                            <select onChange={(e) => {
                                                const transaction = transactions.find(t => t._id === e.target.value);
                                                setSelectedTransaction(transaction);
                                            }}>
                                                <option value="">Select</option>
                                                {transactions.map(transaction => (
                                                    <option key={transaction._id} value={transaction._id}>
                                                        {transaction.pickId != null ? transaction.pickId.title : transaction.packageId === 'Package' ? transaction.packageId.name : transaction.subscriptionId.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {selectedTransaction && (
                                            <div className="form-group mt-3">
                                                <label>Price:</label>
                                                <input type="text" value={selectedTransaction.amount} readOnly />
                                            </div>
                                        )}
                                        <div className="form-group mt-3">
                                            <label>Refund Payment Method:</label>
                                            <select onChange={(e) => setRefundMethod(e.target.value)}>
                                                <option value="">Select</option>
                                                <option value="paypal">PayPal</option>
                                                <option value="bank">Bank</option>
                                            </select>
                                        </div>
                                        {refundMethod === 'paypal' && (
                                            <div className="form-group mt-3">
                                                <label>PayPal Email:</label>
                                                <input type="email" onChange={(e) => setRefundDetails({ ...refundDetails, paypalEmail: e.target.value })} />
                                            </div>
                                        )}
                                        {refundMethod === 'bank' && (
                                            <div className="form-group mt-3">
                                                <label>Bank Name:</label>
                                                <input type="text" onChange={(e) => setRefundDetails({ ...refundDetails, bankName: e.target.value })} />
                                                <label>Account Number:</label>
                                                <input type="text" onChange={(e) => setRefundDetails({ ...refundDetails, accountNumber: e.target.value })} />
                                                <label>Account Title:</label>
                                                <input type="text" onChange={(e) => setRefundDetails({ ...refundDetails, accountTitle: e.target.value })} />
                                            </div>
                                        )}
                                        <div className="form-group mt-3">
                                            <button className="d-block default-button" style={{ margin: '0 auto' }} onClick={handleRefundRequest}><span>Submit Refund Request</span></button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Refund;
