import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const FreePicksList = () => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchGameList = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/user/free-picks`);
                setItems(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching game list:", error);
                setIsLoading(false);
            }
        };

        fetchGameList();
    }, [baseURL]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <section className="collection-section padding-top padding-bottom">
            <div className="container">
                <h2>Free Picks</h2>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center collection-grid GameListStyle">
                        {
                            items.map((elem) => {
                                const { _id, title, match } = elem;
                                return (
                                    <div className="col-12" key={_id}>
                                        <div className="match-item item-layer">
                                            <div className="match-inner">
                                                <div className="match-content bg-white">
                                                    <div className="row gy-4 align-items-center justify-content-center">
                                                        <div className="col-xl-6 col-md-6 order-md-2">
                                                            <div className="match-game-team">
                                                                <ul className="match-team-list d-flex flex-wrap align-items-center justify-content-center">
                                                                    <li className="match-team-thumb">
                                                                        <h4 style={{ color: 'black' }}>{match.homeTeam}</h4>
                                                                    </li>
                                                                    <li className="text-center">
                                                                        <img className="w-75 w-md-100" src="assets/images/match/vs.png" alt="vs" />
                                                                    </li>
                                                                    <li className="match-team-thumb" style={{ color: 'black' }}>
                                                                        <h4 style={{ color: 'black' }}>{match.awayTeam}</h4>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-6 order-md-1">
                                                            <div className="match-game-info">
                                                                <h4 style={{ color: 'black' }}>{title}</h4>
                                                                <p className="d-flex flex-wrap justify-content-center justify-content-md-start">
                                                                    <span className="match-date">{new Date(match?.commenceTime).toLocaleString('en-US')} </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-2 col-md-6 order-md-3">
                                                            <div className="match-game-social">
                                                                <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center justify-content-xl-start">
                                                                    <li>
                                                                        <Link to={`/free-pick/${_id}`} className="default-button reverse-effect"><span>Prediction</span></Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FreePicksList;
