import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const FAQ = () => {

    return (
        <>
            <head>
                <link rel="canonical" href={window.location.href} />
                <title>FAQs | Hit the pick</title>
            </head>
            <Header />
            <PageHeader title={"FAQs"} curPage={"FAQs"} />
            <section className="fore-zero padding-top padding-bottom">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="zero-item">
                            <div className="zero-content" style={{ textAlign: 'start' }}>
                                <h3>Q. What exactly is HIT the Pick?</h3>
                                <p>A.  HIT the Pick is a website that was started and is owned by five of the most successful handicappers in the business who wanted a site that put the customer first. We don’t have a corporate overlord or cringy marketing department making decisions. Everything we do is designed with a “customer first” mentality. 
                                </p>
                                <hr />

                                <h3>Q. Why is “HIT” in all caps?</h3>
                                <p>A. Great question! “HIT” stands for Honesty, Integrity, and Transparency. Those are the three principles that drive everything we do. There are too many shady sites out there that use dishonest tactics and hide their real results in order to con potential customers. That’s the complete opposite of how we do business. </p>
                                <hr />

                                <h3>Q. Who are the five handicappers/owners?</h3>
                                <p>A. Wesley “Sniper Wes” Neil, Lou “Loud Esmond” Desmond,” Tom “WryCape” Macrina, Scott “The Steehnroller” Steehn, and David “Insert Nickname Here” Delano. </p>
                                <hr />

                                <h3>Q. Why doesn’t David Delano have a nickname?</h3>
                                <p>A. We honestly don’t know. But rest assured we are putting massive peer pressure on him daily to come up with one.</p>
                                <hr />

                                <h3>Q. I like to handicap my own games and don’t usually buy picks. Is there anything for me?</h3>
                                <p>A. Absolutely! Each of us will be doing one free pick every day along with daily free pick videos. These will be games we like but they didn’t quite make the cut for a Premium play but are still very solid plays. Our number one goal is to make you a winning sports bettor. Whether that happens with our free plays or our paid selections doesn’t really matter. Separating the books from their money is what we’re all about.</p>
                                <hr />

                                <h3>Q. Are your picks guaranteed?</h3>
                                <p>A. Yes! All of our daily picks come with our exclusive “HIT the Pick” guarantee. Unlike other sites, we give you the option of how you want the guarantee applied. First, you can just take the price you paid and apply it as store credit to your account to spend at a later date. OR you can ride with your current handicapper until you turn a profit. Our five owners are some of the most profitable cappers in the world and we know any slump is temporary and will likely run around very quickly. </p>
                                <hr />

                                <h3>Q. How can I reach you if I have a question or issue?</h3>
                                <p>A. Two ways. You can leave a message at 1 (866) 566-0919 and someone will get back to you very quickly (usually within an hour) or email us at hitthepicksports@gmail.com and we’ll respond as quickly as possible.</p>
                                <hr />

                                <h3>Q. Anything else make you guys different?</h3>
                                <p>A. Yes! We are in the process of doing something no other advisory service does. Similar to casinos, we will soon have a VIP Program in place that will reward our best customers. We appreciate your faith and trust and feel you should be rewarded accordingly. Benefits will include HTP schwag, discounts, and special offers. No one else does this and we are excited to be the very first one to do it.</p>
                                <hr />

                                <h3>Q. It all sounds great but I want to see how you do before I decide to spend my money. Do you have a way to do that?</h3>
                                <p>A. Of course! We know you work hard for your money (so hard for the money) and every handicapper with an internet connection will tell you they are the best. But we put our money where our mouth is by offering everyone who signs up with us <b>$100 in free picks</b> with absolutely no strings attached. It’s the ultimate was to “try before you buy.”</p>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default FAQ;
