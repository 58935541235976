import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import OffersList from "../component/section/offerslist";

class Offers extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Offers'} curPage={'Offers'} />
                <OffersList />
                <Footer />
            </Fragment>
        );
    }
}
 
export default Offers;