import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Leaderboard from "../component/section/leaderboard";

const LeaderboardPage = () => {

    return (
        <>
            <head>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content="Betting Strategies" />
                <meta name="description" content="we break down the best betting strategies for beginners, helping you understand odds, manage your bankroll, and make smarter wagers. Learn how to avoid common mistakes and start your betting journey the right way!" />
                <title>The Best Betting Strategies for New Bettors | Hit the pick</title>
            </head>
            <Header />
            <PageHeader title={"Leaderboard"} curPage={"Leaderboard"} />
            <Leaderboard />
            <Footer />
        </>
    );
};

export default LeaderboardPage;
