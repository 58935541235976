import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";

const BannerCarousal = () => {
    const [hotPicks, setHotPicks] = useState([]);

    useEffect(() => {
        const fetchHotPicks = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get-promotion-banners`);
            setHotPicks(response.data.banner);
        };
        fetchHotPicks();
    }, []);

    return (
        <>
            <div className="hot-pick-carousel" style={{ backgroundColor: 'rgba(8, 18, 90, 0.3)', padding: '20px' }}>
                <Carousel>
                    {hotPicks.map((hotPick) => (
                        <Carousel.Item key={hotPick._id}>
                            <Link className="carousel-content" to={`/handicapper/${hotPick.handicapper._id}`}>
                                <img
                                    src={process.env.REACT_APP_BASE_URL + hotPick?.banner}
                                    alt={'PROMOTION bANNER'}
                                    />
                            </Link>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </>
    );
};

export default BannerCarousal;