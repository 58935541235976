import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import SubscriptionList from "../component/section/subscriptionlist";
import PageHeader from "../component/layout/pageheader";

const Subscriptions = () => {

    return (
        <>
            <head>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content="Betting Predictions" />
                <meta name="description" content="Boost your betting success with our expert predictions and tips for today’s matches. We analyze stats, trends, and odds to bring you the best bets of the day!" />
                <title>Top Betting Predictions & Tips for Today’s Matches | Hit the pick</title>
            </head>
            <Header />
            <PageHeader title={"Subscriptions"} curPage={"Subscriptions"} />
            <SubscriptionList />
            <Footer />
        </>
    );
};

export default Subscriptions;
